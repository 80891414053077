<template>
  <v-container v-if="show" class="pa-0" fluid>
    <v-row align="center" class="bg-primary feature-banner font-weight-bold py-4 px-6 text-white">
      <div v-if="banner">
        {{ banner.body }}
        <span v-if="banner.url"> -
          <a class="text-white" :href="banner.url" target="_blank">
            LEARN MORE
          </a>
        </span>
      </div>
      <v-spacer />
      <qtm-icon-btn class="mr-3" color="white" icon="mdi-close" @click="closed = true" />
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
const closed = ref(false)

const { $api, $error } = useNuxtApp()

interface Banner {
  url: string
  body: string
}
const banner = ref<Banner | undefined>(undefined)

const show = computed(() => !!(!closed.value && banner.value))

async function fetchBanner() {
  try {
    [banner.value] = await $api.v1.notifications.banners.list({ limit: 1 })
  }
  catch (error) {
    $error.report(error)
  }
}

onMounted(async () => {
  await fetchBanner()
  if (show.value) {
    window.addEventListener('scroll', () => {
      closed.value = true
    })
  }
})
</script>

<style lang="scss">
.feature-banner>div>a {
  color: white;
}
</style>
