<template>
  <qtm-dialog-card v-model="showDialog" data-test="po-legal-notice" padding="9" title="Notice" width="325">
    <p class="text-dark-grey">
      The legal contract for the supply and purchase of goods and/or services is between the customer and the vendor
      that you place your order with. QuoteToMe has no control over the actions or omissions of any vendor or the
      quality of product provided.  If you experience problems with your order, contact the vendor directly.
    </p>
    <qtm-checkbox v-model="doNotShowAgain" class="mt-3" label="Do not show me this again" />
    <template v-slot:actions>
      <qtm-btn block class="my-2" data-test="ok-button" size="x-large" @click="acceptLegalNotice">
        Ok
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
const emit = defineEmits(['legal-notice-shown'])

const doNotShowAgain = ref(false)
const showDialog = ref(false)
const legalVersion = ref(0)

const acceptLegalNotice = () => {
  if (doNotShowAgain.value) {
    localStorage.setItem('poLegalVersion', legalVersion.value.toString())
  }
  else {
    localStorage.removeItem('poLegalVersion')
  }
  showDialog.value = false
  emit('legal-notice-shown')
}
const showLegalNotice = () => {
  const version = parseInt(localStorage.getItem('poLegalVersion') || '-1', 10)

  if (Number.isNaN(version) || version < legalVersion.value) {
    showDialog.value = true
  }
  else {
    emit('legal-notice-shown')
  }
}

onMounted(() => {
  showLegalNotice()
})
</script>
